import React from 'react'
import './gui.css'
import iconInfo from '../images/icon-info.svg'
import logo from '../images/logo_cyan.svg'

const Gui = () => {
  return (
    <div>
      <a href='https://pablohumanes.com' >
        <img src={logo} className='logo'></img>
      </a>
      {/*
      <div className='border-top' />
      <div className='border-right' />
      <div className='border-bottom' />
      <div className='border-left' />
      <div className='border-corner-1' />
      <div className='border-corner-2' />
      <div className='border-corner-3' />
      <div className='border-corner-4' />
      */}
      <div className="gui-border">
        <div />
        {/* <div className='gui-tip'>
          <img src={iconInfo} alt="An icon for info" className='icon-info'></img>
          Desliza o arrastra sobre el fondo 3D para rotarlo u orbitar. Utiliza dos dedos o la rueda para hacer zoom.
    </div> */}
      </div>
    </div>
  )
}

export default Gui