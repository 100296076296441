import React, { useRef, useState } from 'react';
import {Helmet} from "react-helmet";
import Controlpad from "../components/Controlpad";
import './tresito.css'

const Tresito = ({ ...location }) => {
  //console.log({location})
  return (
    <div>
      <Helmet>
        <script defer="defer" src="https://pablohumanes.es/glitch/bundle.0ef1b1a61761ab41b893.js" />
      </Helmet>
      <canvas className="webgl"></canvas>
      <Controlpad
      props={ location } 
      />
    </div>
  )
}

export default Tresito