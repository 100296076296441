import React from 'react'

const Loading = () => {
  return (
    <div>
        <div 
        width="20" 
        height="20"
        className="loader"/>
    </div>
  )
}

export default Loading