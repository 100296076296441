import * as React from "react"
import '../layouts/layouts.css'
import { Suspense } from "react"
import { Link } from "gatsby"
//import Tres from '../components/Tres'
import Tresito from '../components/Tresito'
import Loading from '../components/Loading'
import Gui from '../components/Gui'
import { Router, Location } from "@reach/router"
// import { localStorage } from 'node-localstorage' 
// import { store } from "./store.js"
// import { usage } from "./usage.js"

const index = ({ location, children }) => {

  return (
    <div>
        <Suspense fallback={<Loading />}>
        <div className="nav-bar">
        <div className="nav-bar-bg">
          <div className="nav-bar-buttons">
          <Link id='goInicio' className="nav-button" to="/" activeStyle={{ color: "var(--nav-button-color-icon-active)" }} state={{ plays: true }}>
              <svg className="nav-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32.381 32.289">
                <path d="M130.385,45.907c0,.837-.242,1.34-.9,1.428a.861.861,0,0,1-.169.019h-3.537V58.59A2.177,2.177,0,0,1,124.27,60.9a3.021,3.021,0,0,1-.374.1H104.661a2.4,2.4,0,0,1-.374-.1,2.176,2.176,0,0,1-1.513-2.313V47.354s-3.213.02-3.7,0c-.784-.029-1.073-.54-1.073-1.447,0-1.153.2-1.387,1.074-2.293,1.046-1.093,13.529-14.129,13.529-14.129a2.364,2.364,0,0,1,1.672-.77,2.3,2.3,0,0,1,1.661.77s2.942,3.16,6.088,6.5V32.94s.01-1.142,1.625-1.388a4.159,4.159,0,0,1,.635-.04h.374a4.109,4.109,0,0,1,.625.04c1.627.235,1.635,1.388,1.635,1.388v8.172c1.24,1.29,2.148,2.235,2.4,2.5.877.906,1.064,1.14,1.064,2.293Z"
                transform="translate(-98.004 -28.714)"
                fill="currentcolor" />
              </svg>Inicio</Link>    
          <Link className="nav-button" to="/portfolio/" activeStyle={{ color: "var(--nav-button-color-icon-active)" }}>
            <svg className="nav-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 4 41.678 22">
                <g transform="translate(298.725 227.289) rotate(180)">
                <path d="M239.533,78.4a18.727,18.727,0,0,0-6.1.877c-4.431,1.579-4.562,7.677-.395,9.827,1.755.877,3.29,2.281,3.29,4.343a7.479,7.479,0,0,1-3.685,6.1,5.368,5.368,0,0,0-.614,8.818c1.536,1.141,3.9,1.974,7.5,1.974,11.45,0,20.707-7.151,20.707-15.969S250.939,78.4,239.533,78.4Zm-.877,27.726a2.5,2.5,0,0,1-2.5-2.5,2.528,2.528,0,0,1,2.5-2.5,2.5,2.5,0,0,1,2.5,2.5A2.528,2.528,0,0,1,238.655,106.123Zm.7-19.259a2.5,2.5,0,0,1-2.5-2.5,2.472,2.472,0,0,1,2.5-2.5,2.5,2.5,0,1,1,0,5Zm8.818,17.636a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,248.175,104.5Zm.439-14.609a2.5,2.5,0,0,1-2.5-2.5,2.472,2.472,0,0,1,2.5-2.5,2.5,2.5,0,1,1,0,5Zm5.4,7.414a2.472,2.472,0,0,1-2.5-2.5,2.5,2.5,0,0,1,2.5-2.5,2.528,2.528,0,0,1,2.5,2.5A2.5,2.5,0,0,1,254.01,97.305Z"
                transform="translate(38.485 116.954)" fill="currentcolor"/>
                <path d="M95.129,91.12a6.75,6.75,0,0,1,2.457.439l1.053-13.951a3.452,3.452,0,1,0-6.888,0l.921,13.95a6.756,6.756,0,0,1,2.457-.439Z"
                transform="translate(165.911 121.077)" fill="currentcolor"/>
                <path d="M87.991,333.2A3.969,3.969,0,0,0,84,337.146c0,2.369,2.588,3.773,2.062,7.195a.709.709,0,0,0,1.1.7c3.685-2.369,4.782-5.572,4.782-7.853a3.969,3.969,0,0,0-3.948-3.992Z"
                transform="translate(173.049 -117.886)" fill="currentcolor"/></g>
            </svg>
            Portfolio</Link>
          <Link id='vista' className="nav-button" to="/about/" activeStyle={{ color: "var(--nav-button-color-icon-active)" }}  state={{ plays: true }}>
            <svg className="nav-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30.264 32.289">
              <g transform="translate(-152.128 -11.192)">
              <path d="M425.487,39.686H419.33V33.61Z" transform="translate(-243.432 -21.08)" fill="currentcolor"/>
              <path d="M230.65,223.988h1.25a1.337,1.337,0,1,0,0-2.674h-1.25a4.3,4.3,0,0,0-4.292,4.292v1.972a4.3,4.3,0,0,0,4.292,4.292h1.25a1.337,1.337,0,1,0,0-2.674h-1.25a1.619,1.619,0,0,1-1.618-1.618v-1.972a1.615,1.615,0,0,1,1.618-1.618Z"
              transform="translate(-66.978 -198.581)" fill="currentcolor"/>
              <path d="M358.627,220.957a1.336,1.336,0,0,0-1.584,1.029l-1.19,5.515-1.043-5.515a1.337,1.337,0,1,0-2.627.5l1.317,6.992a2.349,2.349,0,0,0,2.3,1.932h.033a2.356,2.356,0,0,0,2.32-1.872l1.5-6.979a1.337,1.337,0,0,0-1.023-1.6Z"
              transform="translate(-185.27 -198.191)" fill="currentcolor"/>
              <path d="M181.984,17.59l-3.677-3.57-2.474-2.44a1.336,1.336,0,0,0-.969-.388h-21.4a1.341,1.341,0,0,0-1.337,1.337V42.143a1.341,1.341,0,0,0,1.337,1.337h27.59a1.341,1.341,0,0,0,1.337-1.337v-23.6a1.32,1.32,0,0,0-.408-.956Zm-4.185-.321h-1.558V15.731l.2.2ZM165.832,40.807a11.033,11.033,0,0,1-11.03-11.03V13.866h18.759v4.74a1.341,1.341,0,0,0,1.337,1.337h4.82V40.806Z"
              fill="currentcolor"/></g>
            </svg>
            Sobre mi</Link>
          <Link id='goContact' className="nav-button" to="/contact/" activeStyle={{ color: "var(--nav-button-color-icon-active)" }}>
            <svg className="nav-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 27.723 32.289">
              <path d="M104.372,67.325a.85.85,0,0,0-.77.85V92.512a.85.85,0,0,0,.85.85h5.287l-.983,5.247a.85.85,0,0,0,1.342.837l8.2-6.084h12.18a.85.85,0,0,0,.85-.85V68.175a.85.85,0,0,0-.85-.85H104.452c-.027,0-.053,0-.08,0Zm4.789,7.227a2.989,2.989,0,0,1,2.976,2.976.85.85,0,1,1-1.7,0,1.275,1.275,0,0,0-2.551,0,.85.85,0,1,1-1.7,0,2.989,2.989,0,0,1,2.976-2.976Zm16.605,0a2.989,2.989,0,0,1,2.976,2.976.85.85,0,1,1-1.7,0,1.275,1.275,0,1,0-2.551,0,.85.85,0,1,1-1.7,0,2.989,2.989,0,0,1,2.976-2.976Zm-1.222,7.253a.85.85,0,0,1,.611,1.528,11.941,11.941,0,0,1-15.383,0,.85.85,0,0,1,.452-1.514c.027,0,.053,0,.08,0a.85.85,0,0,1,.545.2,10.187,10.187,0,0,0,13.231,0h0a.851.851,0,0,1,.465-.213Z"
              transform="translate(-103.602 -67.324)" fill="currentcolor" fillRule="evenodd"/>
            </svg>
            Contacto</Link>
          </div>
        </div>
        </div>
        <Gui />
        <div className="canvas"><Tresito
          props={ location } 
        /></div>
        
        <div>
           <Location>
           {({ location }) => (
             <Router location={location}>
                { children }
            </Router>
            )}

          </Location>
           {/* {React.cloneElement(children, {...location})} */}
        </div>
        </Suspense>
    </div>
  )
}

export default index