import React from 'react';
import './tresito.css'

const Controlpad = ({...location}) => {
    const loki = {...location}
    let classNameDependsOnCondition = loki.props.props.pathname == '/' ? "showcontrolpad" : "hidecontrolpad";

  return (
    <div className={` ${classNameDependsOnCondition }`}>
    <div className="GUI2 guirow1">
        <span className='guirow3'>
        <h4 className="tag">Posicion</h4>
        <input type="range" id="yposition" min="-10" max="10" defaultValue="0" step="0.1" orient="vertical" />
        </span>
        <div className="guirow2">
            <h4 id="tag" className="tag">Glitch encendido</h4>
            <label className="switch">
                <input type="checkbox" id="switchoffglitch" name="switchoffglitch" defaultChecked />
                <span className="slider round"></span>
            </label>
        </div>
    </div>
    </div>
  )
}

export default Controlpad